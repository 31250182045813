import React from 'react'

import * as s from './style.module.sass'
import Button from '../Button'
import ContentWrapper from '../ContentWrapper'

const JoinUs = ({ title, subTitle, link, linkText, ownBg = false }) => (
  <div className={`${s.wrapper} ${ownBg ? s.ownBg : ''}`}>
    <ContentWrapper>
      <div className={s.title}>{title}</div>
      <div className={s.subTitle}>{subTitle}</div>
      <div className={s.buttonWrapper}>
        {/* onClick={() => typeof window !== "undefined" && window.gtag("event", 'Website - Joins us button')} */}
        <a href={link}>
          <Button width={'100%'}>{linkText}</Button>
        </a>
      </div>
    </ContentWrapper>
  </div>
)

export default JoinUs
