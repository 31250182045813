import React from 'react'

import * as styles from './style.module.sass'

const Step = ({ icon, title, message, position = null }) => (
  <div className={`${styles.stepContainer} column is-one-quarter-desktop is-full-touch`}>
    <div className={styles.stepWrapper}>
      {
        position !== null && (
          <div>
            <p className={styles.position}>{position}</p>
            { position < 4 && (<div className={styles.verticalLine} />) }
          </div>
        )
      }
      <div className={styles.textContainer}>
        <div className={`${styles.iconContainer} columns`}>
          <div className={`${position > 1 && styles.horizontalLine} column`} />
          <img
            className={`
              ${position === 1 ? styles.noLeftMargin : ''}
              ${position === 4 ? styles.noRightMargin : ''}
              ${styles.icon}
              is-one-fifth-desktop is-full-touch
            `}
            src={icon.publicURL}
            alt='Step icon'
            width='35'
            height='32'
          />
          <div className={`${(position !== null && position < 4) && styles.horizontalLine} column`} />
        </div>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.message}>{message}</p>
      </div>
    </div>
  </div>
)

export default Step
